.customDateRangePicker .rdrCalendarWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.customDateRangePicker .rdrDateRangeWrapper {
  width: 100%;
}

.customDateRangePicker .rdrDefinedRangesWrapper {
  display: none;
}

.customDateRangePicker .rdrDayNumber span {
  font-weight: bolder;
  font-size: 14px;
}

.custom-date-range-picker .rdrDayDisabled .rdrDayNumber span {
  background-color: transparent !important;
  color: inherit !important;
}

.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrEndEdge {
  filter: none;
}

.rdrDayPassive {
  visibility: hidden;
}
